import React from "react";

function ServiceOne({ items }) {
  return (
    <>
      <div className="row">
        {items.map((item, i) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
            <div className="service service__style--1">
              {item.icon ? <div className="icon">{item.icon}</div> : null}
              <div className="content">
                <h4 className="title">{item.title}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ServiceOne;
