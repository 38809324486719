import React, { Component } from "react";
import {
  FiServer,
  FiBookOpen,
  FiCheckCircle,
  FiLayers,
  FiDatabase,
  FiMonitor
} from "react-icons/fi";

import Layout from "../component/Layout";
import SliderOne from "../component/slider/SliderOne";
import projects from "../data/projects";
import TextLeftOneThird from "../elements/layout/TextLeftOneThird";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import ServiceOne from "../elements/service/ServiceOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import IconBlock from "../elements/IconBlock";
import Projects from "../component/block/Projects";

const yearsOfExperience = new Date().getFullYear() - 2016;

class MainDemo extends Component {
  render() {
    return (
      <Layout
        title="Home"
        cover={
          <div className="slider-wrapper">
            <SliderOne
              title={
                <svg viewBox="0 0 146 65" style={{ width: "100%" }}>
                  <defs>
                    <linearGradient
                      id="myGradient"
                      gradientTransform="rotate(30)"
                    >
                      <stop offset="25%" stop-color="#f5b96a" />
                      <stop offset="100%" stop-color="#ea8b0f" />
                    </linearGradient>
                  </defs>
                  <text fill="url(#myGradient)">
                    <tspan font-size="30" x="0" y="30">
                      node.js
                    </tspan>
                    <tspan font-size="30" x="0" dy="30">
                      experts
                    </tspan>
                  </text>
                </svg>
              }
            >
              <ServiceOne
                items={[
                  {
                    icon: <FiCheckCircle />,
                    title: "Test Automation",
                    description:
                      "All the code written by RIJX is heavily tested at multiple levels: units, integration and end to end."
                  },
                  {
                    icon: <FiBookOpen />,
                    title: "Readable Code",
                    description:
                      "At some point you'll stop working with RIJX. Then any level of developer should be able to easily understand the code."
                  },
                  {
                    icon: <FiLayers />,
                    title: "Iterative Development",
                    description:
                      "Small changes at a time. It keeps the feedback loop short and the software is always left in a working state."
                  }
                ]}
              />
            </SliderOne>
          </div>
        }
      >
        {/* Start About Area */}
        <div className="about-area about-position-top pb--120">
          <About
            title="About Mitchell"
            description="Nice to meet you! RIJX is my brand for software that is pragmatically and cost-efficiently engineered for real world applications. What that means: little to no over engineering and a lot of automated tests."
            items={[
              {
                title: "Self-taught",
                description:
                  "From early age Mitchell became obsessed with programming and automation."
              },
              {
                title: `${yearsOfExperience} years full time`,
                description:
                  `For the last ${yearsOfExperience} years Mitchell has worked as a full time software developer.`
              }
            ]}
            image={{
              url: "/assets/images/team/mitchell.jpg",
              title: "Mitchell"
            }}
          />
        </div>
        {/* End About Area */}

        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <TextLeftOneThird
              title="Services"
              description="There's a few important aspects of software development that RIJX can help you with."
            >
              <div className="row">
                {[
                  {
                    icon: <FiServer />,
                    title: "Backend Development",
                    description:
                      "The backend is about building and consuming APIs to implement your business logic."
                  },
                  {
                    icon: <FiCheckCircle />,
                    title: "Test Automation",
                    description:
                      "Well tested software doesn't just have less bugs. It's also faster to debug and more reliable."
                  },
                  {
                    icon: <FiDatabase />,
                    title: "Database Administration",
                    description:
                      "With a good relational database you can verify your data to always be correct."
                  },
                  {
                    icon: <FiMonitor />,
                    title: "Site Reliability Engineering",
                    description:
                      "Want to see less bugs after code is merged? Better even: do you want to merge straight to production?"
                  }
                ].map((item, i) => (
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <IconBlock {...item} />
                  </div>
                ))}
              </div>
            </TextLeftOneThird>
          </div>
        </div>

        <div className="pt--120 pb--120">
          <Projects />

          <div className="container">
            <BrandTwo
              items={[
                {
                  companyName: "GrandVision",
                  imageUrl: "/assets/images/client-logos/grandvision.gif"
                },
                {
                  companyName: "Pearle Opticiens",
                  imageUrl: "/assets/images/client-logos/pearle-opticiens.jpg"
                },
                {
                  companyName: "bugaboo",
                  imageUrl: "/assets/images/client-logos/bugaboo.png"
                }
              ]}
            />
          </div>
        </div>

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial
              items={projects
                .map((x) => x.testimonials)
                .reduce((result, arr) => result.concat(arr || []), [])}
            />
          </div>
        </div>
        {/* End Testimonial Area */}

        {BlogContent && BlogContent.length ? (
          <div className="portfolio-area pt--110 ptb--25 bg_color--1">
            <div className="portfolio-sacousel-inner mb--55">
              <Portfolio
                title="Blog Posts"
                description="Sharing is caring. A lot of this wouldn't be possible without all the generous, open knowledge sharing in the community. It's time to return the favor."
                items={BlogContent.slice(0, 6)}
              />
            </div>
          </div>
        ) : null}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--110 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne
              items={[
                {
                  countNum: (yearsOfExperience + 8) * 2500,
                  countTitle:
                    "Hours spent on learning how to be a better developer."
                },
                {
                  countNum: yearsOfExperience * 2000,
                  countTitle:
                    "Hours spent working as a professional software developer."
                },
                {
                  countNum: yearsOfExperience * 300,
                  countTitle: "Hours spent working on open source projects."
                }
              ]}
            />
          </div>
        </div>
        {/* End CounterUp Area */}
      </Layout>
    );
  }
}
export default MainDemo;
