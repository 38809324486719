import React from "react";

import projects from "../../data/projects";

import TopLeft from "../content/TopLeft";
import ProjectList from "../element/ProjectList";

function Projects({ hideViewAll }) {
  return (
    <TopLeft
      title="Projects"
      description="Seeing is believing. Below you'll see freelance projects
                    where RIJX significantly contributed to. In which ways will
                    be described in the case study."
      link={
        hideViewAll
          ? null
          : {
              url: "/projects",
              text: "View All Projects"
            }
      }
    >
      <ProjectList items={projects} />
    </TopLeft>
  );
}

export default Projects;
