import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

import CallAction from "../elements/callaction/CallAction";
import Breadcrumb from "../elements/common/Breadcrumb";

import Helmet from "./common/Helmet";
import Header from "./header/Header";
import FooterTwo from "./footer/FooterTwo";

function Layout({ children, cover, title }) {
  if (!title) {
    throw new Error("The layout was not supplied a title");
  }

  return (
    <>
      <Helmet pageTitle={title} />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {cover || <Breadcrumb title={title} />}

      {children}

      <CallAction />

      <FooterTwo />

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </>
  );
}

export default Layout;
