import React from "react";

function BrandTwo({ items }) {
  return (
    <ul className="brand-style-2">
      {items.map(item => (
        <li>
          <img src={item.imageUrl} alt={item.companyName} />
        </li>
      ))}
    </ul>
  );
}

export default BrandTwo;
