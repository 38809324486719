import React from "react";

function TextLeftOneThird({ children, description, title }) {
  return (
    <div className="row">
      <div className="col-lg-4 col-12">
        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
          <h2 className="title">{title}</h2>
          <p>{description}</p>
        </div>
      </div>
      <div className="col-lg-8 col-12 mt_md--50">{children}</div>
    </div>
  );
}

export default TextLeftOneThird;
