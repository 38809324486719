import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 166.027 114.353"
    >
      <g transform="translate(0,-182.647)">
        <path d="m 1.3840043,271.91453 h 2.602015 l 1.88e-4,-61.84088 H 16.042022 c 7.545843,0 12.22947,0.0867 16.132493,2.60201 4.943828,3.20916 7.632577,9.36726 7.632577,14.91822 0,5.03057 -1.387741,9.45399 -5.464232,13.70395 -4.07649,4.07649 -11.709067,4.07649 -16.392694,4.07649 h -1.821411 l 18.821242,26.54055 h 3.295886 L 20.98585,247.71613 c 7.285642,-0.2602 12.66314,-1.0408 17.346767,-7.1989 3.035684,-3.98976 4.249958,-9.02032 4.07649,-13.01008 0,-7.45911 -4.07649,-14.48455 -9.28052,-17.08656 -3.122418,-1.82142 -7.112175,-2.68875 -17.086565,-2.68875 l -14.6578147,-3.1e-4 z" />
        <g transform="translate(2.7596309,-1.3224704)">
          <path d="m 66.244712,273.237 h 2.600184 l -1.84e-4,-64.183 h -2.6 z" />
          <path d="m 91.404712,209.054 h -2.6 l -2e-4,63.48947 c -4e-5,11.56451 -1.15645,19.42827 -11.33322,21.62553 -0.346932,0.11564 -0.809513,0.11535 -1.272093,0.231 v 2.6 c 0.578225,-0.11564 1.156453,-0.11535 1.619033,-0.231 14.22435,-2.65984 13.58648,-14.97392 13.58648,-24.22553 z" />
        </g>
        <path d="m 117.89357,271.91456 h 2.86224 l 20.38249,-30.35684 20.46918,30.35684 h 3.03569 l -22.0304,-32.61192 21.07632,-31.57111 h -3.03568 l -19.51511,29.14256 -19.34169,-29.14256 h -3.12243 l 21.07638,31.57111 z" />
      </g>
    </svg>
  );
}

export default Logo;
