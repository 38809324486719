import React from "react";

import projects from "../data/projects";
import Layout from "../component/Layout";
import ImageText from "../component/card/ImageText";

function ProjectsOverview() {
  return (
    <Layout title="Projects">
      <div className="pt--120 pb--120">
        <div className="container">
          <div className="row">
            {projects.map((item, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-12">
                <ImageText
                  title={item.title}
                  subtitle={item.category}
                  imageUrl={item.imageUrl}
                  link={
                    item.year && item.name
                      ? {
                          url: `/projects/${item.year}/${item.name}`,
                          text: "Read more"
                        }
                      : null
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProjectsOverview;
