import React from "react";

import projects from "../../data/projects";

import TopLeft from "../content/TopLeft";
import ProjectList from "../element/ProjectList";

function OtherProjects({ project }) {
  const visibleProjects = projects.filter(
    (x) => x.year !== project.year || x.name !== project.name
  );

  return (
    <TopLeft
      title="Other projects"
      link={{
        url: "/projects",
        text: "View All Projects"
      }}
    >
      <ProjectList items={visibleProjects} />
    </TopLeft>
  );
}

export default OtherProjects;
