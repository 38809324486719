import formatDate from "date-fns/format";
import React from "react";
import { FiCalendar, FiBriefcase, FiMapPin } from "react-icons/fi";

import OtherProjects from "../component/block/OtherProjects";
import projects from "../data/projects";
import Layout from "../component/Layout";
import NotFound from "../elements/error404";
import IconBlock from "../elements/IconBlock";
import Testimonial from "../elements/Testimonial";

const DEFAULT_BACKGROUND_IMAGE_URL = "/assets/images/bg/bg-image-1.jpg";

function ViewProject({ name, year }) {
  const item = projects.find((x) => x.name === name && String(x.year) === year);

  if (!item) {
    return <NotFound />;
  }

  return (
    <Layout
      title={`Project: ${item.title} (${item.year})`}
      cover={
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${
              item.imageUrl || DEFAULT_BACKGROUND_IMAGE_URL
            })`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h1 className="title theme-gradient">{item.title}</h1>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    {item.startDate ? (
                      <li>
                        <FiCalendar />
                        {formatDate(item.startDate, "MMMM yyyy")} –{" "}
                        {item.endDate
                          ? formatDate(item.endDate, "MMMM yyyy")
                          : "present"}
                      </li>
                    ) : null}
                    {item.location ? (
                      <li>
                        <FiMapPin />
                        {item.location}
                      </li>
                    ) : null}
                    {item.customer ? (
                      <li>
                        <FiBriefcase />
                        {item.customer.name}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {item.tasks && item.tasks.length ? (
        <div className="container pt--60 pb--60">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2>What we did</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {item.tasks.map((item, i) => (
              <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-12">
                <IconBlock {...item} />
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {item.testimonials && item.testimonials.length ? (
        <div className="bg_color--5 ptb--120">
          <div className="container">
            <Testimonial items={item.testimonials} />
          </div>
        </div>
      ) : null}

      <div className="portfolio-related-work pt--120 pb--120 bg_color--1">
        <OtherProjects project={item} />
      </div>
    </Layout>
  );
}

export default ViewProject;
