import React from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";

function Portfolio({ title, description, items }) {
  return (
    <div className="portfolio-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-slick-activation mt--70 mt_sm--40">
        <Slider {...portfolioSlick2}>
          {items.map((value, index) => (
            <div className="portfolio" key={index}>
              <div className="thumbnail-inner">
                <div
                  className="thumbnail"
                  style={{ backgroundImage: `url(${value.image})` }}
                />
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <a href="/portfolio-details">{value.title}</a>
                  </h4>
                  <div className="portfolio-button">
                    <a className="rn-btn" href="/portfolio-details">
                      read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Portfolio;
