import React from "react";

function About({ image, items, title, description }) {
  return (
    <div className="about-wrapper">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="thumbnail">
              {image ? (
                <img className="w-100" src={image.url} alt={image.title} />
              ) : null}
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="about-inner inner">
              <div className="section-title">
                <h2 className="title">{title}</h2>
                <p className="description">{description}</p>
              </div>
              <div className="row mt--30 mt_sm--10">
                {items.map(item => (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list">
                      <h3 className="title">{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
