import React from "react";

function TopLeft({ children, description, title, link }) {
  return (
    <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="section-title text-left">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
            {link ? (
              <a className="btn-transparent rn-btn-dark" href={link.url}>
                <span className="text">{link.text}</span>
              </a>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mt--60 mt_sm--40">{children}</div>
    </div>
  );
}

export default TopLeft;
