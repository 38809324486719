import React from "react";

function SliderOne({ children, title }) {
  return (
    <div className="slider-activation">
      {/* Start Single Slide */}
      <div
        className="slide d-flex align-items-center bg_image bg_image--1"
        data-black-overlay="6"
      >
        <div className="container position-relative">
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-6">
              <div className="inner">
                <h1 className="title theme-gradient" style={{ width: "100%" }}>
                  {title}
                </h1>
              </div>
            </div>
          </div>
          {/* Start Service Area */}
          <div className="service-wrapper service-white">{children}</div>
          {/* End Service Area */}
        </div>
      </div>
      {/* End Single Slide */}
    </div>
  );
}

export default SliderOne;
