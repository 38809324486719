import React from "react";

function IconBlock({ url, icon, title, description }) {
  return (
    <a
      className={`service service__style--2 ${url ? "clickable" : ""}`}
      href={url}
    >
      {icon ? <div className="icon">{icon}</div> : null}
      {title || description ? (
        <div className="content">
          <h3 className="title">{title}</h3>
          <p>{description}</p>
        </div>
      ) : null}
    </a>
  );
}

export default IconBlock;
