import React from "react";

import ImageText from "../card/ImageText";

function ProjectList({ items }) {
  return (
    <div className="row">
      {items.map((item, i) => (
        <div key={i} className="col-lg-4 col-md-6 col-12">
          <ImageText
            title={item.title}
            subtitle={item.category}
            imageUrl={item.imageUrl}
            link={
              item.year && item.name
                ? {
                    url: `/projects/${item.year}/${item.name}`,
                    text: "Read more"
                  }
                : null
            }
          />
        </div>
      ))}
    </div>
  );
}

export default ProjectList;
