const BlogContent = [
  /*{
    image:
      "/assets/images/blog/readable-code-and-flow-control-are-tighly-coupled.jpg",
    title: "Readable code and flow control are tighly coupled",
    category: "Backend"
  },
  {
    image: "/assets/images/blog/the-ultimate-goal-300-test-coverage.jpg",
    title: "The ultimate goal: 300% test coverage",
    category: "Test automation"
  },
  {
    image: "/assets/images/blog/dont-forget-about-postgresql.jpg",
    title: "Don't forget about PostgreSQL",
    category: "Database administration"
  },
  {
    image: "/assets/images/blog/merging-straight-to-production.jpg",
    title: "Merging straight to production",
    category: "Site reliability engineering"
  },
  {
    image: "/assets/images/blog/the-5-minute-pr-review.jpg",
    title: "The 5 minute PR review",
    category: "Site reliability engineering"
  },
  {
    image: "/assets/images/blog/your-code-is-too-dry.jpg",
    title: "Your code is too DRY",
    category: "Test automation"
  }*/
];

export default BlogContent;
