import React from "react";

function ImageText({ title, subtitle, imageUrl, link }) {
  return (
    <div className="blog blog-style--1">
      <div className="thumbnail">
        <a href={link ? link.url : null}>
          {imageUrl ? (
            <img className="w-100" src={imageUrl} alt={title} />
          ) : null}
        </a>
      </div>
      <div className="content">
        {subtitle ? <p className="blogtype">{subtitle}</p> : null}
        {title ? (
          <h4 className="title">
            <a href={link ? link.url : null}>{title}</a>
          </h4>
        ) : null}
        {link ? (
          <div className="blog-btn">
            <a className="rn-btn text-white" href={link.url}>
              {link.text}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ImageText;
