// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

// Home layout
import MainDemo from "./home/MainDemo";
import ViewProject from "./pages/ViewProject";
import ProjectsOverview from "./pages/ProjectsOverview";
import Error404 from "./elements/error404";

import { Router } from "@reach/router";

class Root extends Component {
  render() {
    return (
      <Router>
        <MainDemo path="/" />
        <ProjectsOverview path="/projects" />
        <ViewProject path="/projects/:year/:name" />
        <Error404 default />
      </Router>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
