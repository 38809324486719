import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function Testimonial({ items }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Tabs>
          {items.map(item => (
            <TabPanel>
              <div className="rn-testimonial-content text-center">
                <div className="inner">
                  <p>{item.quote}</p>
                </div>
                <div className="author-info">
                  <h6>
                    <span>{item.name}</span> - {item.jobTitle}
                  </h6>
                </div>
              </div>
            </TabPanel>
          ))}

          <TabList className="testimonial-thumb-wrapper">
            {items.map(item => (
              <Tab>
                <div className="testimonial-thumbnai">
                  <div className="thumb">
                    <img src={item.avatarUrl} alt={item.name} />
                  </div>
                </div>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </div>
    </div>
  );
}

export default Testimonial;
