import React from "react";

import {
  FiMapPin,
  FiShoppingCart,
  FiCpu,
  FiGlobe,
  FiDatabase,
  FiZap
} from "react-icons/fi";

export default [
  {
    year: 2020,
    name: "grandvision",
    imageUrl: "/assets/images/case-studies/2020/grandvision.jpg",
    title: "Expanding My Account features of GrandVision's eCommerce platform",
    category: "Backend development",
    startDate: new Date("2020-01-06"),
    endDate: new Date("2020-04-15"),
    location: "Schiphol, The Netherlands",
    customer: {
      name: "GrandVision N.V."
    },
    tasks: [
      {
        icon: <FiMapPin />,
        title: "Designed, built and tested a new address book microservice.",
        description: (
          <>
            <p>
              We were asked if we could expand the My Account area to support
              manage multiple addresses. Customers would then be able to select
              their previously saved or created addresses when checking out
              their order.
            </p>
            <p>
              Due to limited resources we were asked to save this as JSON on the
              logged in user. This meant we had to be extra cautious with how we
              saved the data. It had to be thoroughly validated before being
              saved.
            </p>
            <p>
              There would also be the possibility of saving an address as your
              preferred billing or delivery addresses. This address would be
              selected automatically when checking out an order or creating an
              appointment.
            </p>
          </>
        )
      },
      {
        icon: <FiZap />,
        title:
          "Taught colleagues how to leverage unit testing to gain more confidence in code.",
        description: (
          <>
            <p>
              When we landed on the project we had to get to know the codebase.
              This logically caused us to make some mistakes in the beginning
              which we didn't catch while developing.
            </p>
            <p>
              This meant that our testers had to explain to us what went wrong,
              we had to get back in the right context, debug the problem and
              send in a new pull request. After this was reviewed it would go to
              the testing environment and it would hopefully be working
              completely.
            </p>
            <p>
              Those who we inherited the codebase from had decided that only
              building end to end tests would suffice to test features and
              detect faults. We decided to not only do that but also start
              trying to cover the majority of our code paths using unit tests.
            </p>
          </>
        )
      }
    ],
    testimonials: [
      {
        quote:
          "I feel like Mitchell is a real node.js developer. He writes easily readable and testable code and has taught me a thing or two about both. In communication he asserts himself well and enthusiastically and always has outspoken opinions. Working with him was overall a very enjoyable experience.",
        name: "Oscar Bartman",
        jobTitle: "Node.js Backend Developer at Rockstars IT",
        avatarUrl: "/assets/images/testimonials/ob.jpg"
      }
    ]
  },
  {
    year: 2019,
    name: "pearle-opticiens",
    imageUrl: "/assets/images/case-studies/2019/pearle-opticiens.jpg",
    title: "Integrating Pearle Opticiens onto a new Node.js ecommerce platform",
    category: "Backend development",
    startDate: new Date("2019-05-13"),
    endDate: new Date("2019-12-20"),
    location: "Schiphol, The Netherlands",
    customer: {
      name: "GrandVision N.V."
    },
    tasks: [
      {
        icon: <FiShoppingCart />,
        title: "Built support for nested cart lines on top of CommerceTools.",
        description: (
          <>
            <p>
              The objective was to preserve the structure of contactlenses that
              customers were used to. This meant that we needed to be able to
              nest cart lines so that the contactlenses for the left and right
              eye would be separate lines, but grouped beneath a parent cart
              line.
            </p>
            <p>
              Because CommerceTools does not support nested cart lines, we had
              to resort to custom attributes to track the product id and amount
              for each line. To make this work well we also needed to adjust the
              stock tracking system.
            </p>
          </>
        )
      },
      {
        icon: <FiCpu />,
        title:
          "Configured the Kubernetes environments for Pearle.nl, Pearle.be and GrandOptical.be.",
        description: (
          <>
            <p>
              To get the most out of all microservices they all had to
              configured independently for each brand. For each brand there was
              a testing, acceptance and production environment. The production
              environment was basically a pre-production demo until the go live
              day.
            </p>
            <p>
              When time came to go live it was a matter of letting our
              colleagues press the merge button on our pull request to change
              the ingress URLs to the production ones. That made it all worth
              it.
            </p>
          </>
        )
      },
      {
        icon: <FiGlobe />,
        title:
          "Implemented multi-lingual support in e-mail messages to customers.",
        description: (
          <>
            <p>
              Pearle.be and GrandOptical.be were the first multi-lingual brands
              on the platform. The languages that were supported were Dutch and
              French.
            </p>
            <p>
              We had to make sure that all the communication with the customer
              was in their preferred language. This meant we had to change a lot
              of API calls to include their current locale.
            </p>
            <p>
              We had to build our template ID switching mechanic because
              SendGrid does not have a system to have different variations
              (apart from version history) of a template under the same ID. We
              ended up going with implementing a dictionary style object loading
              in the config loader to allow locales to be mapped.
            </p>
          </>
        )
      },
      {
        icon: <FiDatabase />,
        title: "Added support for Azure Blob Storage to stock importer.",
        description: (
          <>
            <p>
              Historically every brand had an SFTP server where they'd upload
              their latest stock levels which would get picked up by an in-house
              stock import tool.
            </p>
            <p>
              The SFTP servers were using very old software because nobody was
              willing to maintain them. This was a growing liability because of
              security concerns and incompatibility with modern SFTP clients.
            </p>
            <p>
              We were tasked with implementing an Azure Blob Storage adapter
              into the tool. This was to make the stock importer easier to roll
              out and easier to maintain.
            </p>
          </>
        )
      }
    ],
    testimonials: [
      {
        quote:
          'Mitchell is an outstanding developer with a broad skill set. He approaches all issues with a "can do" attitude and is not afraid to lead a project. While working together on the global GrandVision e-commerce project, he contributed valuable architectural ideas and solutions to improve the performance of the entire platform. It was a great pleasure to work with him and will probably do it again in a near future.',
        name: "Etienne Tremel",
        jobTitle: "Cloud Native Consultant at FikaWorks",
        avatarUrl: "/assets/images/testimonials/et.jpg"
      }
    ]
  },
  {
    year: 2019,
    name: "bugaboo",
    imageUrl: "/assets/images/case-studies/2019/bugaboo.jpg",
    title:
      "Connecting bugaboo's millions of stroller combinations to ChannelEngine",
    category: "Backend development",
    startDate: new Date("2018-10-25"),
    endDate: new Date("2019-03-29"),
    location: "Amsterdam, The Netherlands",
    customer: {
      name: "Bugaboo International B.V."
    },
    testimonials: [
      {
        quote:
          "Mitchell could boldly tackle new types of problems and help at all levels of delivering the project's software: From participating in the architectural decision making to writing maintainable code.",
        name: "Mohamed Ben Mohamed",
        jobTitle: "Senior Engineer and Systems Architect at Bugaboo",
        avatarUrl: "/assets/images/testimonials/mbm.jpg"
      },
      {
        quote:
          "Mitchell has proved to be a valuable team member at Bugaboo. He brought his own, valuable expertise, and was a contributing team member almost immediately, but he was also able to learn quickly when venturing into new areas.",
        name: "Jonathan Hall",
        jobTitle: "Dev Manager at Bugaboo",
        avatarUrl: "/assets/images/testimonials/jh.jpg"
      },
      {
        quote:
          "Mitchell always showed an exemplary commitment and a strong drive to come up with the smartest solution and deliver it within the shortest timeframe. He also understands the needs of the business, which I believe is an invaluable skill to have as a developer.",
        name: "Erica Gasparini",
        jobTitle: "Freelance Product Owner at Bugaboo",
        avatarUrl: "/assets/images/testimonials/eg.jpg"
      }
    ]
  }
];
